.login-container {
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
}

.login-form {
    display: flex;
    background-color: #232323;
    width: 30%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 10px;
    border-radius: 18px;
}

.login-form .title {
    font-weight: 600;
    font-size: 1.8rem;
    margin-bottom: 20px;
}