* {
    scrollbar-width: none;
}

*::-webkit-scrollbar {
    display: none;
}

.home-container {
    height: calc(100vh - 48px);
    width: 100%;
}

.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background: #242424;
}

.Logo {
    color: #fff;
    font-weight: 900;
    font-size: 32px;
}

.message-container {
    width: 100%;
    height: 100%;
}

.message-container .line {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2px;
}

.message-container .content {
    width: 100%;
    min-height: 55px;
    margin-top: 30px;
    background-color: #262626;
    padding: 12px;
    border-radius: 13px;
    line-height: 1.4;
    font-size: 15px;
}